body {
  background:#f7f7f7;
  font-family: 'Raleway', sans-serif;
}
@media(max-width:990px) {
  body {
    background: #FFF;
  }
}
@media(min-width:990px) {
  .navbar-collapse {
    text-align: right;
    position: absolute;
    right: 60px;
  }
}